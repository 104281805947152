@import "../app_vars";


.container_horizontal-scroll::-webkit-scrollbar {
  height: 0.1rem;
}

.container_horizontal-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container_horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(var(--bs-link-color-rgb));
  outline: rgba(var(--bs-link-color-rgb));
}

.container_vertical-scroll::-webkit-scrollbar {
  width: 0.4rem;
}

.container_vertical-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.container_vertical-scroll::-webkit-scrollbar-thumb {
  background-color: $app-primary;
  border-radius: 2px;
}