@import "style/app_vars";

.app {
  min-height: 100vh;
  margin-top: 80px;
  padding-left: 0;
  background-color: $app-theme-light-general;

  @media screen and (min-width: 1600px) {
    padding-left: 280px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 58px;
    margin-top: 0;
  }
}

.opened {
  transition: all .5s ease;
  padding-left: 280px;
}
.closed {
  transition: all .5s ease;
  padding-left: 58px;
}