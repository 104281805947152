.container {
  position: fixed;
  bottom: 1%;
  right: 0;
  border: none;
  padding: 5px;
  background: transparent;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  transition: scale 1s;

  &:hover {
    scale: 1.1;
  }
}