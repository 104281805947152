.overlay {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 1000;
  overflow-y: scroll;
  pointer-events: auto;
  animation-duration: .2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  will-change: opacity;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}