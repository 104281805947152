$app-primary: #174699 !default;
$app-font-color-menu: #8b939b !default;
$app-theme-dark-m: #292b35 !default;
$app-theme-light-general: #f1f2f7 !default;
$app-loader-size: 3rem !default;
$app-medium-font-size: .7rem !default;
$app-organization-grey: #d3d3d363 !default;
$app-active-organization: rgba(255, 244, 204, 0.6);
$app-input-active: rgba(23, 70, 153, .1);
$app-input-hover: rgba(23, 70, 153, .3);
$box-shadow: 0px 5px 5px 3px rgba(34, 60, 80, 0.3) !default;
$box-shadow-label: 0 0 5px 0.1rem rgba(34, 60, 80, 0.5) !default;
$app-breadcrumbs-active: #6c757d;
$app-secondary-subtitle: rgb(226, 227, 229) !default;