.menu {
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  font-size: .95rem;
  z-index: 10;
  background-color: rgba(41, 43, 53, 1) !important;
  padding: 1rem 1rem 0 1rem;

  &.menu__middle {
    width: 58px;
    padding: 1rem 0 0;
    transition: width .5s ease !important;
    will-change: width;

    &.menu__middle_open {
      padding: 1rem 1rem 0 1rem;
      width: 280px;
    }
  }

  &.menu__mobile {
    left: -280px;
    transition: transform .5s ease;
    will-change: transform;

    &.menu__mobile_open {
      transform: translateX(280px);
    }

    &.menu__mobile_close {
      transform: translateX(-280px);
    }
  }
}



