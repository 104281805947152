@import "../app_vars";

.custom__active {
  background: $app-input-active;
}

// Изменение стилей bootstrap accordion
.accordion-button {
  padding: .7rem;
  border: 1px solid $app-primary;
  border-radius: 5px;
  color: $app-primary;
}

// Отключение toggle button для bootstrap dropdown
.dropdown-toggle:empty::after {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item:active {
  color: inherit !important;
  background-color: inherit !important;
}

.ck-editor__editable {
  min-height: 200px;
}







