@import "style/app_vars";

.menu__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $app-font-color-menu;

  &:hover {
    color: $app-theme-light-general;
  }
}

.active {
  color: white !important;
}

.menu__animation {
  animation-duration: .6s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-name: menuLinkAnimation;
}

@keyframes menuLinkAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}