.menu__animation {
  animation-duration: .6s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-name: menuAnimation;
}

@keyframes menuAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}